import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/itemcard.module.scss'
import ItemBidInfo from './ItemBidInfo'

import BidSelector from './BidSelector'
import { getImageUrl } from '../util/images'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'
import FavoriteItem from './FavoriteItem'

export default function ItemCard({item, auction, auctionState, currentUserId, isCheckedIn, setBidItem, setShowBidModal, updateBidModalAmount }) {

  const [itemBidAmount, setItemBidAmount] = useState()

  const [imageUrl, setImageUrl] = useState()

  const linkObj = {
    itemCode: item.ItemCode,
    auctionCode: auction.AuctionCode,
    link: 'itemPage'
  }

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction, item])

  const isHighBidder = item.CurrentHighBidder === currentUserId

  if (auctionState === 'ended' && !isHighBidder) return <></> 

  return (
    <div className={`${css['card-container']} ${themeCss['shadowed']}`}>
      <Link className={css['card-image']} to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>
        <img src={imageUrl} alt={`${item.Title}`} />
      </Link>

      <Link className={`${css['card-title']} ${themeCss['truncate-twoline']}`} to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>

        {/* causes problems with alphabetization */}
        {/*<CustomMarkdown text={item.Title} noLineBreaks={true} cssClass={`${css['card-title']} ${themeCss['truncate-twoline']}`} />*/}

        <p className={`${css['card-title']} ${themeCss['truncate-twoline']}`}>
          {item.Title}
        </p>
      </Link>

      {auctionState !== 'before' && <ItemBidInfo auctionState={auctionState} isHighBidder={isHighBidder} item={item} 
        auction={auction} currentUserId={currentUserId} /> }

      <FavoriteItem auctionId={auction.AuctionId} itemId={item.ItemId}/>

      {(auctionState === 'started' && isCheckedIn) && (
        <>
          <div className={`${css['bid-selector-container']}`} >
            <BidSelector item={item} itemBidAmount={itemBidAmount} setItemBidAmount={setItemBidAmount}
            updateBidModalAmount={updateBidModalAmount}
            />
          </div>
          <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${css['place-bid-button']}`}
            onClick={() => {
                setBidItem(item)
                setShowBidModal(true)
                updateBidModalAmount(itemBidAmount)
              }}>
              PLACE BID
          </button>
        </>
        )}
      {(auctionState === 'started' && !isCheckedIn) && (
        <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${css['place-bid-button']}`}
          disabled={true}>
        Check In to Place Bid</button>)}

          <CustomMarkdown text={item.Description} noLineBreaks={true} cssClass={`${css['card-description']} ${themeCss['truncate-fourline']}`} linkObj={linkObj} />

      {/*<p className={`${css['card-description']} ${themeCss['truncate-fourline']}`}>{item.Description}</p>*/}
    </div>
  )
}