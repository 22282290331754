import store from 'redux/store';
import { getCrmUser } from 'redux/selectors';
import { doApi } from 'services/api';
import { history } from 'services/history.js'

// giving history, receipt bio and giving summary are added to user at signin
export const getSummary = () => {
  console.log('getSummary!');
  const crmUser = getCrmUser(store.getState())
  return crmUser.givingSummary || {};
};

// installment and schedule are lazy-loaded when the user clicks them
export const getHistoryList = async () => {
  console.log('getHistoryList!');
  if (localStorage.givingHistory) {
    return JSON.parse(localStorage.givingHistory);
  }
  else {
    const givingHistoryResponse = (await doApi({route:'history?type=Chronological'})).success;
    if (!givingHistoryResponse) {
      history.push('systemerror')
      return
    }
    else {
      // filter out planned gift and recurring gift payments
      const givingHistory = givingHistoryResponse.gifts.filter(row => row.revenueType !== 'RP' && row.revenueType !== 'BQ');
      localStorage.setItem('givingHistory', JSON.stringify(givingHistory));
      return givingHistory;
    }
  }
};

export const getRecognitionSummary = async (year) => {
  console.log('getRecognitionSummary!');

  if (localStorage.crmUser === "{}") {
    return [];
  }
  // not caching these for now because I got an error on exceeding local storage quota
  // else if (localStorage['recognitionSummary' + year]) {
  //   return JSON.parse(localStorage.['recognitionSummary' + year]);
  // }
  else {
    const recognitionSummaryResponse = (await doApi({route:'history?type=RecognitionSummary' + (year ? '&year='+year : '')})).success;
    if (!recognitionSummaryResponse) {
      history.push('systemerror')
      return
    }
    else {
      // filter out only GT (grant)
      // PG (planned gift) and ST (pledge), RG (recurring gift) are ok

      // commenting out filtering grants as well to get recognition to line up with amount stored in CRM
      // const recognitionSummary = recognitionSummaryResponse.gifts.filter(gift => ["GT"].indexOf(gift.revenueType) === -1)

      // filtering out BQ (planned gift payment, per Gale on 3/14/23)
      const recognitionSummary = recognitionSummaryResponse.gifts.filter(gift => ["BQ"].indexOf(gift.revenueType) === -1)

      recognitionSummary.forEach(gift => gift.trueRecognitionAmount = ["B","P"].indexOf(gift.giftReportType) > -1 ? gift.recognitionAmount : 0)
      recognitionSummary.sort((a,b) => new Date(a.date) - new Date(b.date))
      // localStorage.setItem('recognitionSummary'+year, JSON.stringify(recognitionSummary));
      return recognitionSummary;
    }
  }
};

export const logPrintOfRecognitionSummary = async (year) => {
  let logYear = year || "Lifetime"
  doApi({route:'logreportdownload/YEGS/' + logYear, method:"post"})
  return {success:true};
};

export const getReceiptBio = () => {
  return getCrmUser(store.getState()).receiptBio || {};
};

export const getRecentGifts = async () => {
  return getCrmUser(store.getState()).recentGifts || {};
};

// installment and schedule are lazy-loaded when the user clicks them
export const getInstallment = async (revenueLookupId) => {
  console.log('getInstallment!');
  return (await doApi({route:'installment/' + revenueLookupId})).success.installments;
};

export const getSchedule = async (revenueLookupId) => {
  console.log('getSchedule!');
  // await doTimeout(5000);
  return (await doApi({route:'schedule/' + revenueLookupId})).success.schedules;
};

export const getReceipts = async (year) => {
  console.log('getReceipts!');
  return (await doApi({route:'getreceipts/' + year})).success.gifts;
};

export const logPrintReceipt = async (revenueLookupId) => {
  console.log('logPrintReceipt!');

  const response = await doApi({route:'printreceipt/' + revenueLookupId})
  console.log(response)
  return
}

export const getGivingSiteLink = (displayAmount, fundNumber) => {
  const givingSiteLink = "https://giving.ucla.edu/campaign"
                          + (process.env.REACT_APP_AWS_ENVIRONMENT !== "prod" ? "Test/Donate.aspx?Test=Y&" : "/Donate.aspx?")
                          + "ref=DP&amount=" + displayAmount + "&fund=" + fundNumber

  return givingSiteLink;
}

// not currently in use
// export const doPayNow = async (e, parentRow) => {
//   e.preventDefault()
//   const winRef = window.open();
//   await getCookies('paynow');
//   winRef.location = getGivingSiteLink(parentRow.pledgeBalance, parentRow.fundNumber);
//   analyticsPayNow(parentRow.revenueLookupId)
// }
