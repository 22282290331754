import { useEffect } from "react"
import { useMutation } from "@tanstack/react-query"

import { showLoading } from "services/util"
import { graphql } from "../api"
import { history } from 'services/history.js'

import { updateSolicitationReviewMutation } from '../graphql/Mutations'

import css from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import PreviewPublishChecklist from "./PreviewPublishChecklist"

export default function SolicitationReview( { auctionDetail, refreshAdminAuctionQuery }) {

  const auctionItems = auctionDetail?.Items

  console.log('auction', auctionDetail)

  const itemKeysToDisplay = ['Description', 'Donor', 'ImageFilename', 'ItemCode', 'MinRaise', 'StartingBid', 'Value']

  const auctionKeysToDisplay = ['AuctionCode', 'AuctionLocation', 'BannerImageName', 'Description', 'EndTime', 'FundName',
      'FundNumber', 'MarketingEffort', 'OrganizerAddress', 'OrganizerName', 'OrganizerWebsite', 'OwnerEmail', 'OwnerId',
      'StartTime', 'SupportEmail', 'SupportPhone', 'Title']

  const {data: updateAuctionResponse, error, isPending, mutate: saveAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateSolicitationReviewMutation, variables: input })
    },
  })

  useEffect(() => {
      showLoading(isPending)
  
      if (error) {
        console.log("Auction update error: ", error)
        console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
      }
      else if (updateAuctionResponse) {
        console.log(updateAuctionResponse)
        console.log('Auction updated: ', updateAuctionResponse?.data.updateSolicitationReview)
        refreshAdminAuctionQuery()
      }
    }, [updateAuctionResponse, error, isPending, refreshAdminAuctionQuery ])

    const updateReview = async (value) => {
      const input = {
        auctionId: auctionDetail.AuctionId,
        passedReview: value
      }
      saveAuction(input)
    }

  if (!auctionDetail) return <></>

  return (
    <div className={`${css['widget-container']} ${css['widget-solicitation']}`}>
      {/* <p> passed review? {auctionDetail.PassedReview ? 'true' : 'false'} </p> */}
      <div className={css['flex-centered-spaced-container']}>
        <h3>{auctionDetail.Title}</h3>

        <PreviewPublishChecklist liveStatus={'before'} auctionDetail={auctionDetail} location={'widget'} />
      </div>
      
      <div className={css['flex-centered-spaced-container']}>
        <button className={`${themeCss['button']} ${themeCss['FilledYellow']}`}
          onClick ={(e) => {history.push({
            pathname: '/auctionhome',
            search: `?isPreview=true&auctionId=${auctionDetail.AuctionCode}`,
        })}}>Preview Test Site</button>
      
        { auctionDetail.PassedReview ? 
        <button className={`${themeCss['button']} ${themeCss['StrokeRed']}`} onClick ={() => {updateReview(false)}}>Remove Solicitation Review</button>
        : <button className={`${themeCss['button']} ${themeCss['StrokeBlue']}`} onClick ={() => {updateReview(true)}}>Approve Solicitation Review</button>
    }
      </div>

      {/* <h3>Auction Details</h3>
      
      {auctionKeysToDisplay && (auctionKeysToDisplay).map((key) => {
        return (
        <div className={css['solicitation-detail-row']} key={key} >
          <h4>
            {key}
          </h4>
          <p>
            {auctionDetail[key]}
          </p>
        </div>)
       })} */}


      {/* <h3>Items Details</h3>
      {auctionItems && (auctionItems).map((item) => {
        
        return (
          <table className={css['admin-table']} key={item.Title}>
            <thead>
              <tr>
              <td>Title</td>
              <td>{item['Title']}</td>
              </tr>
            </thead>
            <tbody>
              {itemKeysToDisplay && itemKeysToDisplay.map((key) => {
                return (
                  <tr key={key}>
                    <td>{key} </td>
                    <td>{item[key]}</td>
                  </tr>)
                      })}
            </tbody>
          </table>
        ) 
      })} */}
    </div>
  )
}