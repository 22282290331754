import { useState } from 'react'

import infoIcon from '../../components/images/info-icon-orange.svg'

import { displayUtcInPacificToUser, toEndingString } from 'sites/auctions/util/dateString'

import css from './sass/confirmmodal.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function ExtendAuctionModal({setShowConfirmModal, saveAuction, auction}) {

  const [extensionTime, setExtensionTime] = useState('')
  const [newTime, setNewTime] = useState('')
  //initialize it in the useState, if it's for ending the auction, it will update in the useEffect
  const [input, setInput] = useState({
    AuctionId: auction.AuctionId,
  })

  const toggleModal = () => {
    setShowConfirmModal(false)
  }

  const extendTimeLabels = ['15 minutes', '30 minutes', '1 hour']

  const selectionActive = (value) => {
    if (value === extensionTime) return 'selector-active'
    else return 'selector-inactive'
  }

  const selectTime = (time) => {

    let currentEndTime = new Date(auction.EndTime)
    let proposedTime

    setExtensionTime(time)

    if (time === '15 minutes') {
      proposedTime = currentEndTime.setMinutes(currentEndTime.getMinutes() + 15)
      setNewTime(displayUtcInPacificToUser(proposedTime))
    }
    if (time === '30 minutes') {
      proposedTime = currentEndTime.setMinutes(currentEndTime.getMinutes() + 30)
      setNewTime(displayUtcInPacificToUser(proposedTime))
    }
    if (time === '1 hour') {
      proposedTime = currentEndTime.setMinutes(currentEndTime.getMinutes() + 60)
      setNewTime(displayUtcInPacificToUser(proposedTime))
    }
    
    setInput({
      ...input,
      EndTime: new Date(proposedTime).toISOString()
    })

  }

               
  return (
    <div className={css['modal-container']}>
      <div className={themeCss['modal-background']} onClick={()=>toggleModal()}> </div>
      <div className={`${css['modal-content']} ${css['top-orange-border']}`}>
  
        <div className={css['simple-body']}>
          <img src={infoIcon} />
          <div className={css['simple-text']}>
            <h3>Are you sure you want to extend the duration of this auction?</h3>
            <p>The auction end time will be postponed by the amount chosen. This cannot be undone.</p>
            
            <div className={css['selector-grid']}>
              <p>Current Ending time:</p>
              <p className={css['selector-newtime']}> {displayUtcInPacificToUser(auction.EndTime)} (Pacific Time) </p>
              <p>Extension Time:</p>
              <div className={css['selector-container']}>
                {extendTimeLabels && extendTimeLabels.map((time) => {
                  return <div key={time} className={`${css[selectionActive(time)]} ${themeCss['shadowed-light']}`} 
                    onClick={() => selectTime(time)}>{time}</div>
                })}
              </div>
              <p>New Ending Time:</p>
              <p className={css['selector-newtime']}> 
                {newTime.length > 0 ? `${newTime} (Pacific Time)` : 'Make selection above.'
                }
              </p>
            </div>

          </div> 
        </div>
        
        {/* {data.errorMessage && <p>Error</p>} */}
        
        <div className={css['button-options']}>
          <button className={`${themeCss['button']} ${themeCss['FilledWhite']} ${themeCss['centered-button']}`}
              onClick={()=>toggleModal()} >Cancel</button>
          
          <button className={`${themeCss['FilledBlue']} ${themeCss['button']} ${themeCss['centered-button']} ${css['modal-buttontext-fixing']}`} 
            onClick={()=> saveAuction(input)}
            disabled={extensionTime.length === 0}
            >Extend Auction</button>
        </div>
      </div>    
    </div>
  )
}