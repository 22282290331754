import { useState, useEffect, useCallback } from 'react'
import { useMutation } from "@tanstack/react-query"

import { showLoading } from 'services/util'
import deleteIcon from '../../../images/deleteIcon.svg'

import { graphql } from '../api'
import { deleteAuctionMutation } from '../graphql/Mutations'

import css from './sass/delete.module.scss'
import themeCss from '../components/sass/theme.module.scss'

export default function AuctionDeleteButton(props) {

  const auction = props.auction
  const location = props.location

  const [showDeleteModal, setShowDeleteModal] = useState(false)
    
  const {data: deleteAuctionResponse, error, isPending, mutate: deleteAuction } = useMutation({
    mutationFn: (input) => {
      return graphql({query: deleteAuctionMutation, variables: {auctionId: input} })
    },
  })

  const showDeleteAuctionModal = useCallback(() => {
    let newValue = !showDeleteModal
    setShowDeleteModal(newValue)
  }, [showDeleteModal])

  useEffect(() => {
    showLoading(isPending)

    if (error) {
      console.log("Auction not deleted: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (deleteAuctionResponse) {
      console.log(deleteAuctionResponse)
      console.log('Auction deleted: ', deleteAuctionResponse.data.deleteAuction.AuctionId)
      showDeleteAuctionModal()

      if (auction.Status === 'PREVIEW') {
        props.history.push({pathname: '/admin/listpreviewauctions'})
      }

    }
  }, [deleteAuctionResponse, error, isPending, props.history, auction, showDeleteAuctionModal])


  let buttonClass= ''
  let buttonText=''
  let divClass = ''

  if (location === 'row') {
    buttonClass = `${themeCss['button']} ${themeCss['FilledYellow']} ${css['delete-button']}`
    buttonText = <img src={deleteIcon} alt='Delete Auction' />
  } else if (location === 'bottom') {
    buttonClass = `${themeCss['FilledYellow']} ${themeCss['button']} ${themeCss['centered-button']}`
    buttonText = 'Delete Auction'
    divClass = css['delete-button-bottom']
  }

  return (
    <div className={divClass}>
      <button title="Delete" className={buttonClass}
      onClick={()=> showDeleteAuctionModal()}>
        {buttonText}
      </button>

      {showDeleteModal && 
        <>
          <div className={themeCss['modal-background']} onClick={()=>showDeleteAuctionModal()}> </div>
          <div className={css['delete-modal']}>
            <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=>showDeleteAuctionModal()} >Close Modal</button>
            <p>
              Are you sure you want to delete {auction.Title}?
            </p>
            <button className={`${themeCss['FilledYellow']} ${themeCss['button']} ${themeCss['centered-button']}`}
              onClick={()=> deleteAuction(auction.AuctionId)}>Delete Auction</button>
          </div>
        </>
      }
    </div>
  )
}