import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from "@tanstack/react-query"

import { adminListAuctions } from '../auctionsadminutil'

import css from '../sass/adminpage.module.scss'
// import themeCss from '../../components/sass/theme.module.scss'

import AuctionCard from '../AuctionCard'
import NoAuctionsFound from '../NoAuctionsFound'
import { showLoading } from 'services/util'

export default function AdminClosedAuctionsPage() {

  const [closedAuctionList, setClosedAuctionList] = useState()

  //to refresh query on changes
  const queryClient = useQueryClient()

  // load auction data by code, so it will work for deep-linking
  const { data: auctions, error, isLoading } = useQuery({
    queryKey: ["allAuctionsListInClosed"],
    queryFn: async () => await adminListAuctions(),
    staleTime: 0
  })

  useEffect(() => {
    showLoading(isLoading)

    if (error) {
      console.log("Auctions not loaded: ", error)
      console.log(error.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (auctions) {
      console.log('loaded results, ', auctions)
    }
  }, [auctions, error, isLoading])

  const refreshUpcomingAuctionsQuery = () => {
    queryClient.invalidateQueries(["allAuctionsListInClosed"])
  }

  useEffect(() => {
    if (!auctions) return

    const sortedAuctions = [...auctions].sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime))
    const closedAuctions = sortedAuctions.filter((auction) => auction.Status === 'CLOSED')

    setClosedAuctionList(closedAuctions)
  }, [auctions])

  return (
    <div>
      <h2 className={css['auction-headers']}>Closed Auctions</h2>
        
      {closedAuctionList?.length > 0 ? closedAuctionList.map((auction) => <AuctionCard state={'closed'} auction={auction} key={auction.AuctionId} />)
        : <NoAuctionsFound status={'closed'} />}

    </div>
  )
}