import css from './sass/itembid.module.scss'

import { formatMoney } from 'services/util'

export default function ItemBidInfo({ item, isHighBidder, auctionState, currentUserId, auction }) {

  const itemBids = auction.Bids.filter(bid => bid.ItemId === item.ItemId && bid.UserId === currentUserId)

  const currentBidText = () => {
    if (auctionState === 'ended') return 'Winning Bid'
    if (item.CurrentBid) return 'Current Bid'
    return 'Starting Bid'
  }

  return (
    <div className={css['high-bid-container']}>
      <div className={css['high-bid-header']}>
        <div>
          <p className={`${css['bid-text']} `}>
            {currentBidText()}
          </p>
          <p className={`${css['bid-text-large']} `}>${formatMoney(item.CurrentBid ? item.CurrentBid : item.StartingBid)}</p>
        </div>
        <p className={isHighBidder ? css['high-bid-notification'] : css['hidden']}>
          {auctionState === 'ended' ?
          'You have won this item!'
          : 'You are the highest bidder'}
        </p>
        <p className={(!isHighBidder && itemBids.length > 0) ? css['outbid-notification'] : css['hidden']}>
          You have been outbid
        </p>

      </div>

      <div className={css['bid-info-box']}>
        { auctionState === 'started' && <p>{item.BidCount} {item.BidCount === 1 ? 'bid' : 'bids'}</p>}
        <p>Min. Raise: ${formatMoney(item.MinRaise)}</p>
        <p>Fair Market Value: ${formatMoney(item.Value)}</p>
      </div>
    </div>
  )
}