import css from './sass/closedauction.module.scss'

export default function ClosedBiddingRow( {attendee, toAttendeePage} ) {

  const TransactionCell = function(options) {
    const {giftStatus, transactionId, index} = options
    return (
      <>
         {index > 0 ? ', ' : ''}
         <b>{giftStatus}</b>: {transactionId}
      </>
    )
  }

  return (
    <tr className={css['closed-auction-table-data-row']}>
      <td>{attendee.FullName}</td>
      <td>{attendee.TotalDue}</td>
      <td>{attendee.TotalPaid}</td>
      <td>{attendee.ItemCount}</td>
      <td>
        {!!attendee.TotalDue && <a className={css['action-link']} onClick={() => toAttendeePage(attendee)}>
          Manage Checkout
        </a>}
      </td>
      <td>{Object.keys(attendee.Transactions).map((giftStatus, index) => {
        return (
          <TransactionCell giftStatus={giftStatus} transactionId={attendee.Transactions[giftStatus]} index={index}/>
        )})
      }</td>
    </tr>
  )
}