import { useState, useEffect } from 'react'

import { formatMoney } from 'services/util'

import checkmark from './images/check-circle.svg'

import css from './sass/notification.module.scss'
import { useSelector } from 'react-redux'
import { getAuctionUser } from '../redux/selectors'
import BidSuccessActivateText from './BidSuccessActivateText'

export default function BidSuccessNotification( { successItem, auctionDetail } ) {
    
  const [showItem, setShowItem] = useState(successItem)
  const [textAlertNumber, setTextAlertNumber] = useState()

  const auctionUser = useSelector(getAuctionUser)

  useEffect(() => {

    if (successItem) setShowItem(successItem)

  }, [successItem])

  // checking on if user has signed up for text messages
  useEffect(() => {
    if (!successItem || !auctionUser) return

    const myRegistration = auctionUser.MyAuctions.filter(registration => {
      return registration.Auction.AuctionId === successItem.AuctionId
    })[0]


    if (myRegistration) {
      setTextAlertNumber(myRegistration.TextAlertNumber)
    }
    
  }, [successItem, auctionUser])

  //if there is an item showing, we need to set a timer for 6 seconds
  // after 6 seconds, the item needs to be removed to prevent any overlaying
  useEffect(() => {
    if (showItem) {
      const timer = setTimeout(() => {
        setShowItem('')
      }, 6000)

      //clean up
      return () => clearTimeout(timer)
    }

  }, [showItem])

  const notificationContainerClasses = (sessionStorage.proxyHeaderVisible)
                                     ? `${css['notification-container']} ${css['proxy-header-adjust']}`
                                     :  `${css['notification-container']} ${css['no-proxy-header']}`

  // key prop in first div triggers SASS/CSS to fully re-render every time showItem changes
  return (
    <>      
      {showItem &&
        <div key={JSON.stringify(showItem)} className={notificationContainerClasses}>
          <div className={css['success-container']}>
            <div className={css['successful-bid']}>
              <div className={css['successful-bid-box-line']}></div>
              <div className={css['successful-bid-top-row']}>
                <img src={checkmark} className={css['successful-bid-icon']} alt={'Bid Successful'} />
                <h4 className={css['successful-bid-header']}>Bid successful!</h4>
                <button className={css['successful-bid-close']}
                  onClick={() => setShowItem()}>&times;</button>
              </div>
              {/* <SuccessTop removeFunction={() => removeSuccess(item.ItemId, item.CurrentBid)} /> */}
              <p className={css['successful-bid-text']}>
                You've bid ${formatMoney(successItem.CurrentBid)} on {successItem.Title}
              </p>
              {!textAlertNumber && <BidSuccessActivateText auction={auctionDetail} />}
              <div className={css['bid-timer']}></div>
            </div>
          </div>
      </div>}
    </>
  )
}