import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getAuctionUser } from '../redux/selectors'
import { getAuctionUserApi, getAuctionByCode } from '../api'
import { showLoading } from 'services/util'

import css from './sass/mypages.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import AuctionEndedCard from '../auctions/AuctionEndedCard'
import MyItemRow from './MyItemRow'
import ItemCard from '../items/ItemCard'
import MyItemCard from '../items/MyItemCard'

export default function MyItems() {
  const [pastAuctionList, setPastAuctionList] = useState([])
  const [stillOweAuctionList, setStillOweAuctionList] = useState([])
  const [allPaidAuctionList, setAllPaidAuctionList] = useState([])
  const [emptyItemsAuctionList, setEmptyItemsAuctionList] = useState([])
  const [currentAuctionList, setCurrentAuctionList] = useState([])
  const [currentFavItems, setCurrentFavItems] = useState([])

  const [userDisplay, setUserDisplay] = useState([])

  const [noItems, setNoItems] = useState(true)
  
  const auctionUser = useSelector(getAuctionUser)
  const currentUserId = auctionUser?.UserId

  useEffect(() => {
    async function refreshAuctionUser() {
      showLoading(true)
      // updates auctionUser in redux/localStorage
      await getAuctionUserApi()
      showLoading(false)
    }

    refreshAuctionUser()
  }, [])

  useEffect(() => {
    const favIds = []
    
    auctionUser.MyAuctions.forEach((reg) => {
      if (reg.Favorites?.length > 0) {
        favIds.push(...reg.Favorites)
      }
      })

    setCurrentFavItems(favIds)

  }, [auctionUser])

  useEffect(() => {
    const doAuctions = async () => {
        if (!auctionUser.MyAuctions)
        return

      const now = new Date()

      const currentAuctions = auctionUser.MyAuctions.map(obj => obj.Auction).filter(auction => auction.StartTime < now.toISOString() && auction.EndTime > now.toISOString())

      for (const auction of currentAuctions) {
        // get full auction details for each auction, so we can get all Bids and all Items (myAuctions currently only shows items the user is winning)
        const currentAuctionDetails = await getAuctionByCode(auction.AuctionCode)
        auction.Bids = currentAuctionDetails.Bids
        auction.AllItems = currentAuctionDetails.Items
      }

      setCurrentAuctionList(currentAuctions)

      const upcomingAuctions = auctionUser.MyAuctions.map(obj => obj.Auction)
                                                     .sort((a, b) => (a.StartTime < b.StartTime) ? -1 : (a.StartTime > b.StartTime) ? 1 : 0)
                                                     .map(auction => { return {...auction, 'displayData': false}})

      const pastAuctions = upcomingAuctions.filter((auction) => {
        return auction.EndTime < now.toISOString()
      })

      setPastAuctionList(pastAuctions)
    }

    doAuctions()

  }, [auctionUser.MyAuctions])

  useEffect(() => {
    if (currentAuctionList.length > 0) {

      // TO DO clean up for efficiency and display efficiency

      let myCurrentDisplay = []

      currentAuctionList.forEach(auction => {
        // Loop through each item in the auction's AllItems
        
        let auctionItems = { 
          currentWinning: [],
          currentOutbid: [],
          currentFav: [],
        }

        auction.AllItems.forEach(item => {
          // Add the "currentlyWinning" property
          if (item.CurrentHighBidder === auctionUser.UserId) {
            item.currentlyWinning = true // User has the highest bid
            auctionItems.currentWinning.push(item)

          } else if (item.BidCount > 0 && item.CurrentHighBidder !== auctionUser.UserId && auction.Bids.some(bid => bid.ItemId === item.ItemId && bid.UserId === auctionUser.UserId)) {
            item.currentlyWinning = false // User has bid, but not the highest bidder
            auctionItems.currentOutbid.push(item)
          }
      
          // Add the "isFav" property
          if (currentFavItems.includes(item.ItemId)) {
            item.isFav = true // Item is a favorite
            auctionItems.currentFav.push(item)
          }
        })
        myCurrentDisplay.push({auction: auction, userItems: auctionItems})
      })
      setUserDisplay(myCurrentDisplay)

    }
  }, [currentAuctionList, auctionUser])


  // console.log(currentItemsBidOn)
  useEffect(() => {

    if (pastAuctionList) {
      const auctionItems = pastAuctionList.map((auction) => auction.Items)
      const auctionItemsPaid = pastAuctionList.map((auction) => auction.Items.every(item => item.Paid === true))

      let allPaid = []
      let stillOwe = []
      let emptyItems = []

      for (let i = 0; i < pastAuctionList.length; i++) {
        let auction = pastAuctionList[i]
        if (auction.Items.length === 0 ) {
          emptyItems.push(auction)
          continue;
        }
        if (auctionItemsPaid[i]) allPaid.push(auction)
        else stillOwe.push(auction)
      }

      setStillOweAuctionList(stillOwe)
      setAllPaidAuctionList(allPaid)
      setEmptyItemsAuctionList(emptyItems)

    }
  }, [pastAuctionList])

  useEffect(() => {

    if (stillOweAuctionList.length === 0 && allPaidAuctionList.length === 0) {
      setNoItems(true)
    } else {
      setNoItems(false)
    }

  }, [stillOweAuctionList, allPaidAuctionList])

  return (
    <div>
      <div className={css['mypages-header']}>
        <h2 className={css['mypages-headings']}>Current Auction Items</h2>

          {userDisplay.length > 0 && userDisplay.map((auction) => {
            return (
              <div className={css['myitems-sections']}>
                <h5>{auction.auction.Title}</h5>
                <h6>Favorite Items</h6>
                <div className={css['myitems-row-cards']}>
                  {auction.userItems.currentFav.length > 0 ? auction.userItems.currentFav.map((item) => {
                    return( <MyItemCard key={item.ItemCode} item={item} auction={auction.auction} 
                      currentUserId={auctionUser.UserId} />)
                  }) : <>add some!</>}
                </div>

                <h6>Items you have been outbid on</h6>
                <div className={css['myitems-row-cards']}>
                  {auction.userItems.currentOutbid.length > 0 ? auction.userItems.currentOutbid.map((item) => {
                    return( <MyItemCard key={item.ItemCode} item={item} auction={auction.auction} 
                      currentUserId={auctionUser.UserId} />)
                    }) : <>no items!</>}
                </div>

                <h6>Items you are currently winning</h6>
                <div className={css['myitems-row-cards']}>
                  {auction.userItems.currentWinning.length > 0 ? auction.userItems.currentWinning.map((item) => {
                    return( <MyItemCard key={item.ItemCode} item={item} auction={auction.auction} 
                      currentUserId={auctionUser.UserId} />)
                  }) : <>no winning items!</>}
                </div>

              </div>
            )})}
      </div>

      <div className={css['mypages-header']}>
        <h2 className={css['mypages-headings']}>My Past Items</h2>
        {!noItems && <p>Below are the items you have won, organized by auction.</p>}
      </div>

      <div className={themeCss['blue-background']}>
        {stillOweAuctionList?.length > 0 && stillOweAuctionList.map((auction) => {
          return (
            <div className={css['mypages-card']}>
              <h3 className={css['mypages-headings']}>{auction.Title}</h3>
              <AuctionEndedCard auction={auction} currentUserId={currentUserId} />
              {auction?.Items?.length > 0 && auction?.Items?.map(item => <MyItemRow item={item} /> )}
            </div>
          )})}
          {allPaidAuctionList?.length > 0 && allPaidAuctionList.map((auction) => {
          return (
            <div>
              {/* <h2 className={css['mypages-headings-white']}>
                Past Auctions
              </h2> */}
              <div className={css['mypages-card']}>
                <h3 className={css['mypages-headings']}>{auction.Title}</h3>
                <AuctionEndedCard auction={auction} currentUserId={currentUserId} />
                {auction?.Items?.length > 0 && auction?.Items?.map(item => <MyItemRow item={item} /> )}
              </div>
            </div>
          )})}
          {noItems && <div>
              <div className={css['mypages-card']}>
                <h3 className={css['mypages-headings']}>You have won no items yet.</h3>
              </div>
            </div>}
      </div>
    </div>
  )
}