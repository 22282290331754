import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import themeCss from '../components/sass/theme.module.scss'
import css from './sass/itemcard.module.scss'
import ItemBidInfo from './ItemBidInfo'

import { getImageUrl } from '../util/images'
import CustomMarkdown from 'sites/auctions/components/CustomMarkdown'
import FavoriteItem from './FavoriteItem'

export default function MyItemCard({item, auction, currentUserId}) {

  const [imageUrl, setImageUrl] = useState()

  
  let auctionState = ''
  const now = new Date()
  const startDate = new Date(auction.StartTime)
  startDate < now ? auctionState = 'started' : auctionState = 'before'

  const linkObj = {
    itemCode: item.ItemCode,
    auctionCode: auction.AuctionCode,
    link: 'itemPage'
  }

  console.log(auction)
  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction, item])

  const isHighBidder = item.CurrentHighBidder === currentUserId

  if (auctionState === 'ended' && !isHighBidder) return <></> 

  return (
    <div className={`${css['card-container']} ${css['myitem-card']} ${themeCss['shadowed']}`}>
      <Link className={css['card-image']} to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>
        <img src={imageUrl} alt={`${item.Title}`} />
      </Link>

      <Link className={`${css['card-title']} ${themeCss['truncate-twoline']}`} to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}>

        <p className={`${css['card-title']} ${themeCss['truncate-twoline']}`}>
          {item.Title}
        </p>
      </Link>

      {auctionState !== 'before' && <ItemBidInfo auctionState={auctionState} isHighBidder={isHighBidder} item={item} 
        auction={auction} currentUserId={currentUserId} /> }

      <FavoriteItem auctionId={auction.AuctionId} itemId={item.ItemId}/>

      <Link to={{ pathname: '/auction/item', search: `?auctionId=${auction.AuctionCode}&itemId=${item.ItemCode}`}}
        className={`${themeCss['button']} ${themeCss['FilledYellow']} ${css['place-bid-button']}`}
      >
        View Item 
      </Link>

          <CustomMarkdown text={item.Description} noLineBreaks={true} cssClass={`${css['card-description']} ${themeCss['truncate-fourline']}`} linkObj={linkObj} />

      {/*<p className={`${css['card-description']} ${themeCss['truncate-fourline']}`}>{item.Description}</p>*/}
    </div>
  )
}