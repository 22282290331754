import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { history } from 'services/history.js'

import { adminCleanupPreviewAuction } from './auctionsadminutil'
import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import PreviewPublishChecklist from './PreviewPublishChecklist'
import UpdateAuctionStatusButton from './UpdateAuctionStatusButton'
import AuctionStatusAlert from './AuctionStatusAlert'
import ExtendAuctionButton from './ExtendAuctionButton'
import { useSelector } from 'react-redux'
import { getAuctionUser } from '../redux/selectors'

export default function PreviewPublish(props) {

  const passedReview = props.auctionDetail?.PassedReview
  const auctionUser = useSelector(getAuctionUser)

  const [readyForSR, setReadyForSR] = useState(false)

  useEffect(() => {
    if (props.auctionDetail?.FundName === "" || props.auctionDetail?.FundNumber === "" || props.auctionDetail?.MarketingEffort === "") setReadyForSR(false)
    else setReadyForSR(true)

  }, [props.auctionDetail])

  const sendToSolicitation = () => {
    console.log('fake send!')
  }

  const auctionStatus = props.auctionDetail?.Status

  const doPreview = async () => {
    await adminCleanupPreviewAuction(props.auctionDetail.AuctionId)
    history.push({ pathname: '/auctionhome', search: `?isPreview=true&auctionId=${props.auctionDetail.AuctionCode}`})
  }

  return (
    <div className={adminCss['widget-container']}>
      <div className={adminCss['widget-status-container']}>
        <AuctionStatusAlert liveStatus={props.liveStatus} auctionStatus={auctionStatus} />
        <PreviewPublishChecklist liveStatus={props.liveStatus} auctionDetail={props.auctionDetail} location={'widget'} />
      </div>

      {auctionStatus === 'PREVIEW' && <div className={adminCss['widget-button-container']}>

        <button className={`${themeCss['button']} ${themeCss['FilledWhite']}`} onClick={doPreview}>Preview Test Site</button>

        <button className={`${themeCss['button']} ${themeCss['FilledWhite']}`}
          disabled={!readyForSR}
          title={readyForSR ? '' : 'Fund Name, Fund Number, and Marketing Effort are all required before sending to Solicitation Review'}
          onClick={() => {sendToSolicitation()}}>
          Send Auction Site and Items to Solicitation Review
        </button>

        <div className={adminCss['solicition-input-container']}>
          <p>Solicitation Review only</p>
          <input type='checkbox'
            checked={passedReview}
            disabled={true} />
            <span>
              Solicitation Review has approved this site and all auction items. 
              </span>
        </div>

        
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PREVIEW'} toStatus={'PUBLIC'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} 
          needsSolicitation={!passedReview} />
      </div>}
      
      {auctionStatus === 'PUBLIC' && <div className={adminCss['widget-button-container']}>
        {/* from public to preview is prevented in UpdateAuctionStatus
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'PREVIEW'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} /> */}
        {props.liveStatus === 'started' &&
        <div className={adminCss['started-button-container']}>
          <ExtendAuctionButton auction={props.auctionDetail} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
          <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'ENDED'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
        </div>}
        {props.liveStatus === 'ended' &&
          <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'PUBLIC'} toStatus={'CLOSED'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />}
      </div>}
      {auctionStatus === 'CLOSED' && <div>
        <UpdateAuctionStatusButton auction={props.auctionDetail} fromStatus={'CLOSED'} toStatus={'PUBLIC'} refreshAdminAuctionQuery={props.refreshAdminAuctionQuery} />
      </div>}
      
      
    </div>
  )
}