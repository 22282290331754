import { useEffect, useState } from 'react'

import css from '../sass/adminitem.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

import { getImageUrl } from '../../util/images'


export default function ClosedItemDisplayRow( {item, auction} ) {

  const [imageUrl, setImageUrl] = useState()

  //set auction kind to silent for now - paddle to come later
  // let kind = 'silent'

  // use effect for auction banner image
  useEffect(() => {
    const url = getImageUrl(auction?.AuctionCode, item?.ItemCode, item?.ImageFilename)
    setImageUrl(url)
  }, [auction, item])

  return (
    <>
      <tr className={css['admin-item-row']}>
        <td>
          <img src={imageUrl} alt={item?.Title}/>
        </td>

        <td>{item?.Title}</td>
        <td>${item?.Value}</td>
        <td>${item?.StartingBid}</td>
        <td>${item?.MinRaise}</td>
        <td>{item?.ItemCode}</td>
        <td>{item?.Donor}</td>
        <td>{item?.BidCount}</td>
        <td className={item.CurrentBid > item.Value ? themeCss['boldGreen']: ''}>${item?.CurrentBid}</td>
        <td>{`${item?.CurrentHighBidderInfo?.FirstName || ""} ${item?.CurrentHighBidderInfo?.LastName || ""}`}</td>
      </tr>
    </>
  )
}